import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Checkbox, Grid, CircularProgress } from '@mui/material';
import { Title, SubTitle, GridWhite, Spacer, DarkGreenButton, CityChoices, convertToHalfWidth } from '../../components/Common';
import { red, orange, blue, gray } from '../../StyleVariables';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AlertError from '../AlertError';

interface InputRowProps {
    isMobile: boolean,
    name: string,
    value: string | string[],
    handleChange: (e: any) => void,
    handleCheckZipCode: (e: any) => void,
    prefectures: any,
    loadingZipcode: any,
    isError: any,
    handleBlur: (e: any) => void,
    handleBlurPhoneNumber: (e: any) => void,
}

const InputRow = ({ isMobile, name, value, handleChange, handleCheckZipCode, prefectures, loadingZipcode, isError, handleBlur, handleBlurPhoneNumber }: InputRowProps) => {
    let title = '';
    let placeholder = '';
    switch(name){
        case 'name': title = 'お名前'; placeholder = '例）山田太郎'; break;
        case 'zipcode': title = '郵便番号'; placeholder = '例）1500031'; break;
        case 'prefecture_id': title = '都道府県'; placeholder = '選択してください'; break;
        case 'address1': title = '市区町村'; placeholder = '例）○○市○○区○○町'; break;
        case 'address2': title = '丁番地'; placeholder = '例）○○1-2-3'; break;
        case 'address3': title = 'マンション・ビル名'; placeholder = '例）○○マンション 101号'; break;
        case 'phone_number': title = '電話番号'; break;
        case 'email': title = 'メールアドレス'; placeholder = '例）○○○@example.com'; break;
        default: break;
    }

    return (
        <GridForm isMobile={isMobile} container alignItems="center" justifyContent="flex-start">
            <Grid item xs={isMobile ? 12 : 4}>
                <FormTitle isMobile={isMobile}>{title}{name === 'address3' ? '': <Star>*</Star>}</FormTitle>
            </Grid>
            <Grid container item xs={isMobile ? 12 : 8} alignItems="center" justifyContent="flex-start">
                {
                    name === 'zipcode' ?
                        <Grid container item xs={12}>
                            <Grid item xs={isMobile ? 6 : 5}>
                                <TextInput required smaller={true} maxLength={7} isMobile={isMobile} name={name} onChange={handleChange} onBlur={handleBlur} value={value} placeholder={placeholder} />
                                {isError ? <p style={{color: '#E80000', fontSize:'14px'}}>入力を確認してください。</p> : null}
                            </Grid>
                            <Grid item xs={isMobile ? 6 : 5}>
                                {loadingZipcode ? 
                                    <OrangeButton isMobile={isMobile} disabled={loadingZipcode ? true : false}>
                                        <CircularProgress size="25px" style={{color: 'white', marginLeft: '20px'}}/>
                                    </OrangeButton>
                                :
                                    <OrangeButton isMobile={isMobile} onClick={handleCheckZipCode}>住所自動入力</OrangeButton>
                                }
                            </Grid>
                        </Grid>
                    :
                    name === 'prefecture_id' ?
                        <SelectGrid xs={isMobile ? 12 : 5} isMobile={isMobile} item>
                            <SelectWrapper isMobile={isMobile} className="select-wrapper">
                                <SelectInput isMobile={isMobile} required onChange={handleChange} name={name} value={value}>
                                    {prefectures.map((pref: any, i: any) => (
                                        <option key={"pref-"+i} value={pref.id}>{pref.name}</option>
                                    ))}
                                </SelectInput>
                            </SelectWrapper>
                        </SelectGrid>
                    :
                    name === 'phone_number' ?
                    <Grid container item xs={12} style={{marginTop: '10px'}}>
                        <Grid container item xs={12}>
                            {[1,2,3].map((i, idx) => (
                                <Grid item xs={isMobile ? 4 : 3}>
                                    <TextInput required smaller={true} key={'phone-'+i} isMobile={isMobile} name={'phone_number_' + i} onChange={handleChange} onBlur={handleBlurPhoneNumber} value={value[idx]} placeholder={i === 1 ? '例）000' : '0000'} />
                                </Grid>
                            ))}
                            
                        </Grid>
                        <Grid item xs={12}>
                            {isError ? <p style={{color: '#E80000', fontSize:'14px'}}>入力を確認してください。</p> : null}
                        </Grid>
                    </Grid>
                    :
                        <Grid>
                            <TextInput required={(name === 'address3') ? false : true} onChange={handleChange} onBlur={handleBlur} isMobile={isMobile} name={name} value={value} placeholder={placeholder} />
                            {isError ? <p style={{color: '#E80000', fontSize:'14px'}}>入力を確認してください。</p> : null}
                        </Grid>
                }
            </Grid>
        </GridForm>
    )
}

interface Props {
    isMobile: boolean,
    goToNextPage: () => void,
    goToPrevPage: () => void,
    prefectures: any,
    values: any,
    setValues: (values: any) => void,
}

export default function Step2 ({ isMobile, goToNextPage, goToPrevPage, prefectures, values, setValues }: Props) {

    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [loadingZipcode, setLoadingZipcode] = useState(false);
    const [errorMessage, setError] = useState("");
    const [cityChoices, setCityChoices] = useState<any|null>(null);
    const [errorValues, setErrorValues] = useState<any>({
        name: false,
        zipcode: false,
        prefecture_id: false,
        address1: false,
        address2: false,
        address3: false,
        phone_number: false,
        email: false
    });

    const handleChange = (e: any) => {
        if(e.target.name === 'phone_number_1' || e.target.name === 'phone_number_2' || e.target.name === 'phone_number_3'){
            let oldPhoneNumber = values.phone_number;
            switch(e.target.name){
                case 'phone_number_1': oldPhoneNumber[0] = String(e.target.value); break;
                case 'phone_number_2': oldPhoneNumber[1] = String(e.target.value); break;
                case 'phone_number_3': oldPhoneNumber[2] = String(e.target.value); break;
                default: break;
            }
            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });
        } else {
            setValues({
                ...values,
                [e.target.name]: String(e.target.value)
            });
        }
    }

    const handleBlur = (e: any) => {
        const name = e.target.name;
        let value = convertToHalfWidth(e.target.value);
        if(name === 'name'){
            value = value.substring(0,16);
            value = value.replace(/[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9]/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        } else if(name === 'zipcode'){
            value = value.substring(0,7);
            value = value.replace(/[^0-9]/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        } else if(name === 'address1' ){
            value = value.substring(0,12);
            value = value.replace(/[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        } else if(name === 'address2'){
            value = value.substring(0,16);
            value = value.replace(/[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        } else if(name === 'address3'){
            value = value.substring(0,16);
            value = value.replace(/[^A-Z a-z Ａ-ｚぁ-んァ-ン一-龥ーぁ-ゔゞァ-・ヽヾ゛゜ー々ゝゞ（）髙﨑塚德濵栁瀨隆𠮷　0-9 -]/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        // } else if(name === 'phone_number_1' || name === 'phone_number_2' || name === 'phone_number_3'){
        //     value = value.substring(0,5);
        //     value = value.replace(/[^0-9]/g, '');
        //     let oldPhoneNumber = values.phone_number;
        //     switch(name){
        //         case 'phone_number_1': oldPhoneNumber[0] = String(value); break;
        //         case 'phone_number_2': oldPhoneNumber[1] = String(value); break;
        //         case 'phone_number_3': oldPhoneNumber[2] = String(value); break;
        //         default: break;
        //     }
        //     setValues({
        //         ...values,
        //         'phone_number': oldPhoneNumber
        //     });
        } else if(name === 'email'){
            value = value.substring(0,255);
            value = value.replace(/[^a-zA-Z0-9._%+-]+@[\w%\+\-]+(\.[\w%\+\-]+)+$/g, '');
            setValues({
                ...values,
                [name]: String(value)
            })
        } 
        else {
            setValues({
                ...values,
                [name]: String(value)
            })
        }
        let errs = handleCheckValues(errorValues, name, value);
        setErrorValues(errs)
    }

    const handleBlurPhoneNumber = (e: any) => {
        const name = e.target.name;
        let value = convertToHalfWidth(e.target.value);
        if(name === 'phone_number_1' ){
            value = value.substring(0,5);
            value = value.replace(/[^0-9]/g, '');
            let oldPhoneNumber = values.phone_number;
            
            oldPhoneNumber[0] = String(value);

            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });
        } else if(name === 'phone_number_2' ){
            value = value.substring(0,4);
            value = value.replace(/[^0-9]/g, '');
            let oldPhoneNumber = values.phone_number;
            
            oldPhoneNumber[1] = String(value);

            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });
        } else if(name === 'phone_number_3'){
            value = value.substring(0,4);
            value = value.replace(/[^0-9]/g, '');
            let oldPhoneNumber = values.phone_number;
            
            oldPhoneNumber[2] = String(value)

            setValues({
                ...values,
                'phone_number': oldPhoneNumber
            });
        }  else {
            setValues({
                ...values,
                [name]: String(value)
            })
        }
        let errs = handleCheckValues(errorValues, name, value);
        setErrorValues(errs)
    }

    const handleCheckZipCode = () => {
        setLoadingZipcode(true);
        fetch('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + values.zipcode, { method: 'GET' })
            .then((response) => response.json())
            .then((response: any) => {
            if(response && response.results){
                if(response.results.length === 1){
                    const pref_id = response.results[0].prefcode
                    setValues({
                        ...values,
                        prefecture_id: pref_id,
                        address1: response.results[0].address2 + ' ' + response.results[0].address3
                    });
                    setLoadingZipcode(false);
                    // setError('');
                    setErrorValues({
                        ...errorValues,
                        prefecture_id: false,
                        address1: false
                    })
                } else {
                    //more than 1
                    setCityChoices(response.results);
                    setLoadingZipcode(false);
                    // setError('');
                    setErrorValues({
                        ...errorValues,
                        prefecture_id: false,
                        address1: false
                    })
                }
            } else {
                setError('見つかりません') // Not Found
                setLoadingZipcode(false);
                setValues({
                    ...values,
                    address1: ''
                });
            }
        });
    }

    const handleCheckPrivacy = (e: any) => {
        setPrivacyChecked(e.target.checked)
    }

    function chooseCity (e: any) {
        let index = e.target.id.split('-')[1];
        if(cityChoices){
            let choice = cityChoices[index];
            if(choice && (typeof choice === 'object')){
                setValues({
                    ...values,
                    prefecture_id: parseInt(choice?.prefcode),
                    address1: choice?.address2 + ' ' + choice?.address3
                });
                setLoadingZipcode(false);
            }
        }
        setCityChoices(null);
        setErrorValues(false)
    }

    function handleCheckValues (errs: any, name: any, value: any){
        if(name === 'name'){
            if(!value){
                errs.name = true;
            } else {
                if(value){
                    errs.name = false;
                }
            }
        } 
        if(name === 'zipcode'){
            if(!value){
                errs.zipcode = true;
            } else {
                if(value){
                    errs.zipcode = false;
                }
            }
        } 
        if(name === 'prefecture_id'){
            if(!value){
                errs.prefecture_id = true;
            } else {
                if(value){
                    errs.prefecture_id = false;
                }
            }
        } 
        if(name === 'address1'){
            if(!value){
                errs.address1 = true;
            } else {
                if(value){
                    errs.address1 = false;
                }
            }
        } 
        if(name === 'address2'){
            if(!value){
                errs.address2 = true;
            } else {
                if(value){
                    errs.address2 = false;
                }
            }
        } 
        if(name === 'phone_number_1'){
            const totalLength = (value.length + values.phone_number[1].length + values.phone_number[2].length)
            if(!value){
                errs.phone_number = true;
            } else {
                if(value.length < 2 || value.length > 5){
                    errs.phone_number = true;
                } else if (totalLength < 10 || totalLength > 11) {
                    errs.phone_number = true;
                } else {
                    errs.phone_number = false;
                }
            }
        } 
        if(name === 'phone_number_2' ){
            const totalLength = (values.phone_number[0].length + value.length + values.phone_number[2].length)
            if(!value){
                errs.phone_number = true;
            } else {
                if(value.length < 3 || value.length > 4){
                    errs.phone_number = true;
                } else if (totalLength < 10 || totalLength > 11) {
                    errs.phone_number = true;
                } else{
                    errs.phone_number = false;
                }
            }
        } 
        if(name === 'phone_number_3'){
            const totalLength = (values.phone_number[0].length + values.phone_number[1].length + value.length)
            if(!value){
                errs.phone_number = true;
            } else {
                if(value.length < 3 || value.length > 4){
                    errs.phone_number = true;
                } else if (totalLength < 10 || totalLength > 11) {
                    errs.phone_number = true;
                } else{
                    errs.phone_number = false;
                }
            }
        } 
        if(name === 'email'){
            if(!value){
                errs.email = true;
            } else {
                if(value){
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[\w%\+\-]+(\.[\w%\+\-]+)+$/
                    if(!emailRegex.test(values.email)){
                        errs.email = true;
                    } else{
                        errs.email = false;
                    }
                }
            }
        } 
        return errs;
    }

    function submit(){
        let errs = errorValues
        // console.log(errorValues)
        Object.keys(values).forEach((val) => {
            if(val === 'phone_number'){
                errs = handleCheckValues(errs, 'phone_number_1', values[val][0])
                errs = handleCheckValues(errs, 'phone_number_2', values[val][1])
                errs = handleCheckValues(errs, 'phone_number_3', values[val][2])
            } else {
                errs = handleCheckValues(errs, val, values[val])
            }
        });
        // console.log(errs)
        let hasError = false;
        Object.keys(errs).map((errName) => {
            if(errs[errName]){
                hasError = true;
            }
        })
        if(hasError){
            // setErrorValues(errs);
            setError("入力を確認してください。"); // Please check your input
            window.scrollTo(0,0);
        } else {
           goToNextPage(); 
        }
    }

    return (
        <div>
            <Title isMobile={isMobile} addMargin={true}>
                ご優待品の送付先をご入力ください。
            </Title>
            {cityChoices ?
                <CityChoices 
                    cityChoices={cityChoices} 
                    chooseCity={chooseCity} 
                />
            : 
                null
            }
            {errorMessage ?
                <div style={{marginBottom: '20px', width: '100% !important'}}>
                    <AlertError 
                        isMobile={isMobile}
                        errorMessage={errorMessage}
                    />
                </div>
            :
                null
            }
            <GridWhite container>
                {Object.keys(values).map((k, i) => (
                    <InputRow 
                        key={'inputidx'+i}
                        isMobile={isMobile}
                        name={k}
                        value={values[k]}
                        handleChange={handleChange}
                        handleCheckZipCode={handleCheckZipCode}
                        prefectures={prefectures}
                        loadingZipcode={loadingZipcode}
                        isError={errorValues[k]}
                        handleBlur={handleBlur}
                        handleBlurPhoneNumber={handleBlurPhoneNumber}
                    />
                ))}
                <Grid item xs={12}>
                    {isMobile && <Spacer />}
                </Grid>
            </GridWhite>

            <Spacer />
            
            <Title isMobile={isMobile} addMargin={true}>
                株主様の個人情報の取扱いについて
            </Title>

            <GridWhite container justifyContent="center">
                <Grid item>
                    <Spacer />
                    <PrivacyPolicyLink target="_blank" href="https://www.delica.co.jp/privacy/" isMobile={isMobile}>個人情報保護方針<OpenInNewIcon /></PrivacyPolicyLink>
                </Grid>
                <Grid item xs={isMobile ? 12 : 11}>
                    <SubTitle isMobile={isMobile}>ご提供いただいた個人情報は、ご優待品発送の目的のみに使用し、他の目的には利用いたしません。上記の個人情報保護方針に同意いただいた上でお進みください。</SubTitle>
                </Grid>
                <Grid container item justifyContent="space-around">
                    <Grid item>
                        <Title isMobile={isMobile}><Checkbox onChange={handleCheckPrivacy} checked={privacyChecked} />個人情報保護方針に同意する</Title> 
                    </Grid>
                    <Grid item xs={12}>
                        <Spacer />
                    </Grid>
                </Grid>
            </GridWhite>

            <Spacer />

            <Grid container>
                <Grid container item xs={6}>
                    <GrayButton isMobile={isMobile} onClick={goToPrevPage}>戻る</GrayButton>
                </Grid>
                <Grid container item xs={6}>
                    <DarkGreenButton isMobile={isMobile} disabled={(privacyChecked === false)} onClick={submit}>次へ</DarkGreenButton>
                </Grid>
            </Grid>
        </div>
    )
}

const FormTitle = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 16 : 20,
    fontWeight: 600
}));

const Star = styled("span")(() => ({
    color: red
}));

const GridForm = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    padding: isMobile ? '-2px 10px -2px 20px' : '10px'
}));

const TextInput = styled('input')<{isMobile: boolean, smaller?: boolean}>(({isMobile, smaller}) => ({
    width: smaller ? '80%' : '100%',
    fontSize: isMobile ? 15 : 20,
    padding: isMobile ? '7px 0px 7px 7px' : '10px',
    height: isMobile ? 25 : 28,
    borderRadius: 5,
    border: '1px solid gray',
    marginRight: '10px'
}));

const SelectGrid = styled(Grid)<{isMobile: boolean}>(({isMobile}) => ({
    paddingRight: isMobile ? '10px' : '7px',
}));

const SelectWrapper = styled('div')<{isMobile: boolean}>(({isMobile}) => ({
    width: isMobile ? '99%' : '89%',
    borderRadius: 5,
    border: '1px solid gray',
    padding: '3px 0px 3px 3px',
}));

const SelectInput = styled('select')<{isMobile: boolean}>(({isMobile}) => ({
    width: '90%',
    padding: isMobile ? '4px 0px 4px 4px' : '10px',
    height: isMobile ? 39 : 48,
    fontSize: isMobile ? 15 : 20,
    border: 'none',
}));

const OrangeButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: orange,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: '100%',
    height: isMobile ? '40px' : '48px',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '-10px',
    marginRight: '20px'
}));

const GrayButton = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: gray,
    fontWeight: 'bold',
    fontSize: isMobile ? 24 : 32,
    width: '100%',
    height: isMobile ? '48px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '15px',
    marginBottom: '20px',
    marginRight: '20px',
    cursor: 'pointer'
}));

const PrivacyPolicyLink = styled("a")<{isMobile: boolean}>(({isMobile}) => ({
    color: blue,
    fontSize: isMobile ? 24 : 32,
    textAlign: 'center',
    textDecoration: 'none'
}));