import { styled } from 'styled-components';
import { lightGreen, darkGreen } from '../StyleVariables';
import { Dialog, DialogTitle, DialogContent, Grid, Box } from '@mui/material';

const Title = styled("p")<{isMobile: boolean, addMargin?: boolean}>(({isMobile, addMargin = false}) => ({
    color: 'black',
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 24,
    margin: addMargin ? '20px 0px' : '0px',
    padding: '0px'
}));

const SubTitle = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontSize: isMobile ? 14 : 24,
    whiteSpace: 'pre-line'
}));

const GridWhite = styled(Grid)(() => ({
    border: '2px solid ' + darkGreen,
    padding: '5px 10px 0px 10px',
    margin: '0px',
    height: 'auto'
}));


const Spacer = styled("br")(() => ({
    height: '20px'
}));

const DarkGreenButton = styled("button")<{isMobile: boolean, disabled?: boolean}>(({isMobile, disabled}) => ({
    color: 'white',
    backgroundColor: disabled ? lightGreen : darkGreen,
    fontWeight: 'bold',
    fontSize: isMobile ? 16 : 20,
    width: isMobile ? '100%' : '500px',
    height: isMobile ? '48px' : '80px',
    borderRadius: '10px',
    border: 'none',
    margin: 'auto',
    marginTop: '15px',
    marginBottom: '20px',
    display: 'flex',
    justifySelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: disabled ? 'auto' : 'pointer'
}));

interface DialogProps {
    isOpen: boolean,
    close: () => void,
    isMobile: boolean,
    children: any
}

const GridCommon = styled(Grid)(() => ({
    padding: '10px',
    margin: '0px',
    height: 'auto',
    paddingBottom: '20px'
}));

function DetailPopUp ({isOpen, close, isMobile, children}: DialogProps) {
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                <GridCommon container>
                    {children}
                </GridCommon>
                <DarkGreenButton isMobile={isMobile} onClick={close}>
                    詳細を閉じる
                </DarkGreenButton>
            </DialogContent>
        </Dialog>
    )
}

interface CityChoicesProps {
    cityChoices: any,
    chooseCity: any
}

function CityChoices ({cityChoices, chooseCity}: CityChoicesProps) {
    return (
        <Dialog
            open={cityChoices ? true : false}
            maxWidth={"md"}
        >
            <DialogTitle>
                住所を選択してください
            </DialogTitle>
            <DialogContent>
                {cityChoices.map((address: any, index: any) => {
                    return (
                        <div style={{cursor: 'pointer', border: '1px solid orange', borderRadius: '5px', padding: '5px 10px 5px 10px', marginBottom: '10px', textAlign: 'center'}} onClick={chooseCity} id={"address-"+index}>
                            {address.address1}, {address.address2} {address.address3}
                        </div>
                    )
                })}
            </DialogContent>
        </Dialog>
    )
}

const Image = styled("img")<{isMobile: boolean}>(({isMobile}) => ({
    width: '100%',
    objectFit: "contain",
    objectPosition: "center center"
}));

const OutsideImage = styled(Box)<{isMobile: boolean}>(({isMobile}) => ({
    width: '100%',
    border: '1px solid black',
}));

const OutsideImage2 = styled(Box)<{isMobile: boolean}>(({isMobile}) => ({
    width: '300px',
    height: '300px',
    border: '1px solid black',
}));

function convertToHalfWidth (str: any) {
    if (typeof str === 'string' || str instanceof String){
        return str.replace(
            /[\uff01-\uff5e]/g,
            function(ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
        );
    }
    else {
        return str;
    }
}

interface AlertBackPopupProps {
    open: boolean,
    closeModal: () => void,
    leavePage: () => void,
    isMobile: boolean,
}

function AlertBackPopup ({isMobile, closeModal, open, leavePage}: AlertBackPopupProps) {
    return (
        <Dialog
            open={open}
            onClose={closeModal}
        >
            <DialogContent>
                <GridCommon container>
                    Are you sure for leave this page?
                </GridCommon>
                <DarkGreenButton isMobile={isMobile} onClick={leavePage}>
                    Yes
                </DarkGreenButton>
                <DarkGreenButton isMobile={isMobile} onClick={closeModal}>
                    No
                </DarkGreenButton>
            </DialogContent>
        </Dialog>
    )
}

export { Title, SubTitle, GridWhite, Spacer, DarkGreenButton, DetailPopUp, GridCommon, Image, CityChoices, convertToHalfWidth, AlertBackPopup, OutsideImage, OutsideImage2 }